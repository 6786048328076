<template>
  <div class="d-flex align-items-center" style="width: 100%; gap: 0.675rem">
    <!--    <div style="max-width: 7rem; min-width: 5rem; width: 100%">-->
    <!--      <label>Enrolled</label>-->
    <!--      <v-select-->
    <!--          :value="includeEnrolledFilter"-->
    <!--          :options="includeEnrolledOptions"-->
    <!--          class="w-100 drop-shadow-default "-->
    <!--          :reduce="(val) => val.value"-->
    <!--          :clearable="false"-->
    <!--          placeholder="Enrolled"-->
    <!--          @input="(val) => $emit('update:includeEnrolledFilter', val)"-->
    <!--      />-->
    <!--    </div>-->
    <div style="max-width: 12.5rem; min-width: 11.5rem; width: 100%">
      <label>Funding</label>
      <v-select
        :value="fundingFilter"
        :options="fundingOptions"
        class="w-100 drop-shadow-default"
        :reduce="(val) => val.value"
        placeholder="All Sources"
        @input="(val) => $emit('update:fundingFilter', val)"
      />
    </div>
    <!--    <div style="max-width: 10.5rem; min-width: 7rem; width: 100%">-->
    <!--      <label>Foresight</label>-->
    <!--      <v-select-->
    <!--          :value="foresightFilter"-->
    <!--          :options="foresightOptions"-->
    <!--          class="w-100 drop-shadow-default "-->
    <!--          :reduce="(val) => val.value"-->
    <!--          placeholder=""-->
    <!--          @input="(val) => $emit('update:foresightFilter', val)"-->
    <!--      />-->
    <!--    </div>-->
    <div style="max-width: 8rem; min-width: 7.5rem; width: 100%">
      <label>Resume</label>
      <v-select
        :value="resumeFilter"
        :options="resumeOptions"
        class="w-100 drop-shadow-default"
        :reduce="(val) => val.value"
        placeholder="All"
        @input="(val) => $emit('update:resumeFilter', val)"
      />
    </div>
    <div style="max-width: 8rem; min-width: 7.5rem; width: 100%">
      <label>COE</label>
      <v-select
        :value="coeFilter"
        :options="coeOptions"
        class="w-100 drop-shadow-default"
        :reduce="(val) => val.value"
        placeholder="All"
        @input="(val) => $emit('update:coeFilter', val)"
      />
    </div>
    <div style="max-width: 10.5rem; min-width: 7rem; width: 100%">
      <label>VA Submission</label>
      <v-select
        :value="submissionFilter"
        :options="submissionOptions"
        class="w-100 drop-shadow-default"
        :reduce="(val) => val.value"
        placeholder="All"
        @input="(val) => $emit('update:submissionFilter', val)"
      />
    </div>
    <div style="max-width: 11.5rem; min-width: 9.5rem; width: 100%">
      <label>Status</label>
      <v-select
        :value="statusFilter"
        :options="statusOptions"
        class="w-100 drop-shadow-default"
        :reduce="(val) => val.value"
        placeholder="All"
        @input="(val) => $emit('update:statusFilter', val)"
      />
    </div>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText, BFormDatepicker } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import { onUnmounted, ref } from '@vue/composition-api';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BCardActions,
    BCardText,
    vSelect,
  },
  data: () => {
    return {
      includeEnrolledOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    };
  },
  methods: {
    pathwayFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const pathwaysPromise = this.$store.dispatch('placementStoreModule/fetchCohorts', {
          search: this.pathwayQuery,
        });

        Promise.all([pathwaysPromise]).then((values) => {
          this.pathwaySuggestions = [];

          const pathways = values[0].data.results;

          pathways.length && this.pathwaySuggestions.push({ name: 'pathways', data: pathways });
        });
      }, 250);
    },
    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('rosterStoreModule/fetchPathwayList', { search });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: id, label: name };
      });
    },
  },
  props: {
    includeEnrolledFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    foresightFilter: {
      type: [String, null],
      default: null,
    },
    foresightOptions: {
      type: Array,
      required: true,
    },
    createAtStartFilter: {
      type: [String, null],
      default: null,
    },
    createAtEndFilter: {
      type: [String, null],
      default: null,
    },

    fundingFilter: { type: [String, null], default: null },
    fundingOptions: { type: Array, required: true },
    resumeFilter: { type: [String, null], default: null },
    resumeOptions: { type: Array, required: true },
    coeFilter: { type: [String, null], default: null },
    coeOptions: { type: Array, required: true },
    submissionFilter: { type: [String, null], default: null },
    submissionOptions: { type: Array, required: true },
  },
  mounted() {
    this.searchForPathway('');
  },
  setup(props, { emit }) {},
};
</script>
